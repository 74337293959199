import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import React, { useCallback } from "react";
import { bgGradient } from "src/theme/css";
import { useTheme } from "@emotion/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Logo from "src/components/logo";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../components/iconify";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import AuthService from "src/services/Auth.service";

const ResetPasswordView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onSubmit(values, setSubmitting, resetForm);
    },
  });
  const onSubmit = useCallback(async (formData, setSubmitting, reset) => {
    try {
      setSubmitting(true);
      const res = await AuthService.sendOTP(formData.email);
      if (res.success) {
        toast.success(res.message);
        // navigate("/verify-otp");
        navigate(`/change-password/${formData.email}`);
      } else toast.error(res.message);
    } catch (error) {
      toast.error("Error in sending link:", error);
    } finally {
      setSubmitting(false);
      reset();
    }
  }, []);
  const renderForm = (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="email"
        label="Email address"
        variant="outlined"
        sx={{ width: 1, mb: 3 }}
        value={formik.values.email}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="start">
                <Iconify icon="eva:email-fill" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        fullWidth
        size="large"
        color="inherit"
        type="submit"
        sx={{ py: 1, fontSize: 18 }}
        disabled={formik.isSubmitting}
        endIcon={formik.isSubmitting && <CircularProgress size={20} />}
      >
        Request OTP
      </Button>
    </form>
  );
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card sx={{ maxWidth: 450, p: 5, width: 1, position: "relative" }}>
          <Stack direction="row" alignItems="center" spacing={5}>
            <KeyboardBackspaceIcon
              fontSize="small"
              onClick={() => navigate(-1)}
              style={{
                cursor: "pointer",
              }}
            />
            <Typography variant="h4" style={{ paddingLeft: 10 }}>
              Forgot Password?
            </Typography>
          </Stack>
          <Typography variant="body2" sx={{ mt: 2, mb: 5, opacity: 0.7 }}>
            Please enter your registered email address and we will send a
            verification code
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Email address
          </Typography>
          {renderForm}
          <Typography
            variant="body2"
            sx={{
              mt: 3,
              textAlign: "center",
            }}
          >
            Remember your password?{" "}
            <Link
              to="/login"
              style={{
                color: "white",
                opacity: 0.6,
                textDecoration: "none",
              }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              Sign in
            </Link>
          </Typography>
        </Card>
      </Stack>
    </Box>
  );
};

export default ResetPasswordView;
