import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import AuthService from "src/services/Auth.service";
import { toast } from "react-hot-toast";
import RoleService from "src/services/Role.service";
import PermissionService from "src/services/Permission.service";
import { analytics } from "src/config/firebase.config";
import { logEvent } from "firebase/analytics";

const sessionKey = "DmKK1qWkfeeDm3PhdWMnXihmKPl8B0";

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  permissions: [],
};

const setSession = (
  accessToken,
  refreshToken,
  accessExpiresAt,
  refreshExpiresAt
) => {
  if (accessToken && refreshToken) {
    const session = {
      accessToken,
      refreshToken,
      accessExpiresAt,
      refreshExpiresAt,
    };

    const sessionEncripted = btoa(JSON.stringify(session));
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem(sessionKey, sessionEncripted);
    localStorage.setItem("sessionKey", JSON.stringify(session));

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("refreshToekn");
    localStorage.removeItem(sessionKey);
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, isInitialized } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isInitialized: true,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,

  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const session = localStorage.getItem(sessionKey);

        const { accessToken, refreshToken, accessExpiresAt, refreshExpiresAt } =
          JSON.parse(atob(session));

        const accessExpiryDateTime =
          accessExpiresAt && new Date(accessExpiresAt);

        const refreshExpiryDateTime =
          refreshExpiresAt && new Date(refreshExpiresAt);

        const allTokenAndExpiryExists =
          !!accessToken &&
          !!refreshToken &&
          !!accessExpiryDateTime &&
          !!refreshExpiryDateTime; //problemm

        const refreshTokenIsValid =
          Number(new Date()) < Number(refreshExpiryDateTime);

        if (!(allTokenAndExpiryExists && refreshTokenIsValid)) {
          toast.info("Session expired! Login again.");

          dispatch({
            type: "INITIALIZE",
            payload: {
              isInitialized: true,
              isAuthenticated: false,
              user: null,
            },
          });

          return;
        }

        const accessTokenIsValid =
          Number(new Date()) < Number(accessExpiryDateTime);

        const loggedInUser = await AuthService.getLoggedInUser(accessToken);

        if (accessTokenIsValid && loggedInUser) {
          setSession(
            accessToken,
            refreshToken,
            accessExpiresAt,
            refreshExpiresAt
          );

          // const role = await RoleService.getWithPermissions(loggedInUser.role);

          // const { name: roleName, authScopes } = role.data;

          // localStorage.setItem(
          //   "p",
          //   btoa(PermissionService.flattenObject(authScopes).toString())
          // );

          dispatch({
            type: "INITIALIZE",
            payload: {
              isInitialized: true,
              isAuthenticated: true,
              user: { ...loggedInUser },
              // user: { ...loggedInUser, role: roleName },
              // permissions: PermissionService.flattenObject(authScopes),
              permissions: [],
            },
          });
          return;
        }

        const tokenRes = await AuthService.refreshTokens(refreshToken);

        if (!tokenRes.success) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isInitialized: true,

              isAuthenticated: false,
              user: null,
            },
          });
          return;
        }

        const {
          access: { token: newAccessToken, expires: newAccessExpiresAt },
          refresh: { token: newRefreshToken, expires: newRefreshExpiresAt },
        } = tokenRes.data;

        setSession(
          newAccessToken,
          newRefreshToken,
          newAccessExpiresAt,
          newRefreshExpiresAt
        );

        const reLoggedInUser = await AuthService.getLoggedInUser(
          newAccessToken
        );

        // const role = await RoleService.getWithPermissions(loggedInUser.role);
        // const { name: roleName, authScopes } = role.data;

        // localStorage.setItem(
        //   "p",
        //   btoa(PermissionService.flattenObject(authScopes).toString())
        // );

        dispatch({
          type: "INITIALIZE",
          payload: {
            isInitialized: true,

            isAuthenticated: true,
            user: { ...reLoggedInUser },
            // user: { ...reLoggedInUser, roleName },
            permissions: [],
            // permissions: PermissionService.flattenObject(authScopes),
          },
        });
      } catch (err) {
        // console.error(err);
        console.log(err);
        console.log("REDUX ERROR", { err });

        dispatch({
          type: "INITIALIZE",
          payload: {
            isInitialized: true,

            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (
    email = null,
    password = null,
    success = "",
    message = "",
    data = {}
  ) => {
    const TOAST_ID = "loging_context";
    let loginres;
    toast.loading("Logging in...", { id: TOAST_ID });
    if (!password) {
      loginres = await AuthService.OauthLogin(success, message, data).catch(
        (err) => toast.error("Login failed!", { id: TOAST_ID })
      );
    } else {
      loginres = await AuthService.login(email, password).catch((err) =>
        toast.error("Login failed!", { id: TOAST_ID })
      );
    }

    if (!loginres.success) {
      console.log("Login failed!: ", loginres.message);
      toast.error(loginres.message ?? "Login failed!", { id: TOAST_ID });
      return;
    }

    const {
      user,
      // role,
      token: { access, refresh },
    } = loginres.data;
    // console.log("user:", user);
    // const { authScopes, name: roleName } = role;

    // user.role = roleName;

    const accessToken = access.token;
    const expiresAt = access.expires;

    const refreshToken = refresh.token;
    const refreshExpiresAt = refresh.expires;

    setSession(accessToken, refreshToken, expiresAt, refreshExpiresAt);
    logEvent(analytics, "button_click", { button_name: "Sign In" });
    toast.success("Successfully logged in", { id: TOAST_ID });

    // localStorage.setItem(
    //   "p",
    //   btoa(PermissionService.flattenObject(authScopes).toString())
    // );

    dispatch({
      type: "LOGIN",
      payload: {
        user,
        // permissions: PermissionService.flattenObject(authScopes),
        permissions: [],
      },
    });

    window.location.href = "/";
  };

  const logout = async () => {
    const TOAST_ID = "SIGNING_OFF";

    toast.loading("Signing out...", { id: TOAST_ID });
    const { success, message } = await AuthService.logout().catch(
      (err) => err.message
    );

    setSession();
    dispatch({ type: "LOGOUT" });
    if (success) return toast.success("Signed out!", { id: TOAST_ID });

    toast.success(message, { id: TOAST_ID });
    logEvent(analytics, "button_click", { button_name: "Logout" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
