import { useEffect, useCallback, forwardRef, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import Dreamer from "./images/dreamer.svg";
// import Blank from "./images/blank.svg";
import Empty from "./images/empty.svg";
// import Void from "./images/void.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { alpha, Box } from "@mui/material";
import axios from "axios";
import CommentService from "src/services/Comment.service";
import { format } from "timeago.js";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/config/firebase.config";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DiscussionModal({ open, setOpen, articleid, owner }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasPressedEnter, setHasPressedEnter] = useState(false);
  const [comments, setComments] = useState([]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };
  const [discussion, setDiscussion] = useState("");

  const handleCommentSubmit = async () => {
    if (discussion.trim() === "") {
      console.log("Empty comment input...");
      return;
    }

    const commentData = {
      comment: discussion,
      article: articleid,
      owner: owner,
    };

    try {
      await CommentService.createComment(commentData);
    } catch (error) {
      console.log("Error in creating comment:", error);
    } finally {
      setDiscussion("");
      setHasPressedEnter(false);
      fetchComments();
    }
  };

  const fetchComments = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedComments = await CommentService.fetchComments(articleid);
      if (fetchedComments) {
        setComments(fetchedComments);
      }
    } catch (error) {
      console.log("Error in fetching comments: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;
    logEvent(analytics, "button_click", { button_name: "View comments" });
    fetchComments();
  }, [articleid, open]);

  useEffect(() => {
    if (hasPressedEnter) {
      handleCommentSubmit();
    }
  }, [hasPressedEnter]);
  useEffect(() => {
    document.getElementById("comment")?.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        setHasPressedEnter(true);
      }
    });
  }, [discussion]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      hideBackdrop={false}
      sx={{
        maxHeight: "85vh",
        mt: "auto",
      }}
      PaperProps={{
        elevation: 1,
        style: {
          backdropFilter: "blur(10px)",
          backgroundColor: alpha(theme.palette.background.paper, 0.05),
          border: `4px solid ${theme.palette.background.paper}`,
          borderRadius: "25px",
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ opacity: 0.8 }}>Discussions</DialogTitle>
        <IconButton
          color="white"
          aria-label="close"
          sx={{ height: 40, width: 40, mr: 1, mt: 1 }}
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="small" color="error" />
        </IconButton>
      </Stack>
      <DialogContent sx={{ minHeight: "60vh" }}>
        {Boolean(isLoading) && (
          <>
            {Array(3)
              .fill(0)
              .map((skels, index) => (
                <ListItem
                  sx={{
                    mb: 1,
                  }}
                  alignItems="flex-start"
                  key={index}
                >
                  <ListItemAvatar>
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" alignItems="center">
                        <Skeleton animation="wave" height={20} width={50} />
                        <Typography sx={{ mx: 1 }}>•</Typography>
                        <Skeleton animation="wave" height={10} width={20} />
                      </Stack>
                    }
                    secondary={
                      <Stack>
                        <Skeleton animation="wave" height={15} width="100%" />
                        <Skeleton animation="wave" height={15} width="40%" />
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
          </>
        )}
        {Boolean(!isLoading && !comments.length) && (
          <>
            <Box
              component="img"
              sx={{
                width: 300,
                height: 300,
                mx: "auto",
                display: "block",
                "&:after": {
                  position: "absolute",
                  top: 0,
                  content: "''",
                  height: "100%",
                  width: "100%",
                  bgcolor: () => alpha(theme.palette.grey[900], 0.12),
                },
              }}
              src="/assets/illustrations/comments.png"
              alt="empty"
            />

            <Typography
              variant="h4"
              sx={{ textAlign: "center", my: 3, fontWeight: 500, opacity: 0.8 }}
            >
              No discussion yet, be the first one!
            </Typography>
          </>
        )}
        {Boolean(!isLoading && comments.length) && (
          <List>
            {comments.map((comment) => (
              <ListItem
                key={comment._id}
                sx={{
                  pb: 2,
                  mb: 2,
                  borderBottom: `1px solid ${theme.palette.background.paper}`,
                }}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  <Avatar
                    src="https://avatar.iran.liara.run/public/boy"
                    alt="HK"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Stack direction="row" alignItems="center">
                      <Typography variant="h6">
                        {comment.owner?.name || "anonymous"}
                      </Typography>
                      <Typography sx={{ mx: 1 }}>•</Typography>
                      <Typography variant="caption">
                        {format(comment.createdAt, "en_US")}
                      </Typography>
                    </Stack>
                  }
                  secondary={comment.comment}
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <TextField
          fullWidth
          name="discussion"
          id="comment"
          placeholder="Write your thoughts..."
          value={discussion}
          onChange={(event) => {
            setDiscussion(event.target.value);
          }}
          sx={{ background: theme.palette.background.paper, borderRadius: 20 }}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCommentSubmit();
                  }}
                >
                  <SendRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
