import {
  Box,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { bgGradient } from "src/theme/css";
import { useTheme } from "@emotion/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Logo from "src/components/logo";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../components/iconify";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import OTPInput from "./OTPInput";

const VerifyOTPView = () => {
  const theme = useTheme();
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .min(6, "OTP must be 6 digits"),
  });
  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onSubmit(values, setSubmitting, resetForm);
    },
  });
  const onSubmit = useCallback(async (formData, setSubmitting, reset) => {
    try {
      setSubmitting(true);
      toast.success("OTP verification successful", formData.otp);
      navigate("/change-password/");
    } catch (error) {
      toast.error("Error in verifying code:", error);
    } finally {
      setSubmitting(false);
      reset();
    }
  }, []);
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (otp.trim() === "") {
      toast.error("Please enter OTP");
      return;
    }
    try {
      if (otp) toast.success(`OTP submitted: ${otp}`);
      navigate("/change-password");
    } catch (err) {
      toast.error(err);
    }
  };

  const renderForm = (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="body1" sx={{ mb: 2, ml: 2 }}>
        Enter the 6-digit code
      </Typography>
      <TextField
        name="otp"
        label="Enter OTP"
        variant="outlined"
        sx={{ width: 1, mb: 3 }}
        value={formik.values.otp}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.otp && Boolean(formik.errors.otp)}
        helperText={formik.touched.otp && formik.errors.otp}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <VpnKeyRoundedIcon fontSize="medium" />
            </InputAdornment>
          ),
        }}
      />

      {/* handlesubmit left for this component */}
      {/* <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
        <OTPInput otp={otp} setOtp={setOtp} />
      </Box> */}

      <Button
        variant="contained"
        fullWidth
        size="large"
        color="inherit"
        type="submit"
        onSubmit={onSubmit}
        // onClick={(e) => handleOTPSubmit(e)}
        sx={{ py: 1, fontSize: 18, letterSpacing: 1 }}
        disabled={formik.isSubmitting}
      >
        Verify
      </Button>
    </form>
  );
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card sx={{ maxWidth: 450, p: 5, width: 1, position: "relative" }}>
          {/* <IconButton
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: 20,
            }}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon fontSize="small" />
          </IconButton> */}
          <Stack direction="column" alignItems="center">
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Verify OTP
            </Typography>

            <Typography
              variant="body2"
              sx={{ mb: 5, opacity: 0.7, textAlign: "center" }}
            >
              We've sent a verification code to your registered mail
            </Typography>
          </Stack>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
};

export default VerifyOTPView;
