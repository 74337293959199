import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard/index";
import AddAdvertisement from "../pages/add-advertisement";

import Authenticated from "src/components/Authenticated";
import ResetPassword from "src/pages/forgot-password/reset-password";
import ChangePassword from "src/pages/forgot-password/change-password";
import ProfilePage from "src/pages/profile";
import ValidateOrg from "src/components/ValidateOrg";
import FeedOrg from "src/pages/Org/feed-org";
import HomeOrg from "src/pages/Org/home-org";
import PrivacyPolicy from "src/pages/privacy-policy";
import Terms from "src/pages/terms";
import AppInitView from "src/sections/appInit/view/appInit-view";
import { AppBar, Button, Container, Toolbar } from "@mui/material";
import Nav from "src/layouts/dashboard/nav";
import Logo from "src/components/logo";
import useAuth from "src/hooks/useAuth";
import SignupPage from "src/pages/signup";
import VerifyOTP from "src/pages/forgot-password/verify-otp";
import SaanviPage from "src/pages/saanvi";

const HomePage = lazy(() => import("../pages/home"));
const TrendingPage = lazy(() => import("../pages/trending"));
const DiscoverPage = lazy(() => import("../pages/user"));
const AddArticlePage = lazy(() => import("../pages/add-article"));
const LoginPage = lazy(() => import("../pages/login"));
const Page404 = lazy(() => import("../pages/page-not-found"));
// const AddAdvertisement = lazy(() => import("../pages/add-advertisement"));
export default function Router() {
  const { isAuthenticated, isInitialized } = useAuth();

  const isReadyAndLoggedIn = Boolean(isAuthenticated && isInitialized);

  const routes = useRoutes([
    !isReadyAndLoggedIn && {
      element: (
        <Suspense fallback={<AppInitView />}>
          <AppBar
            position="static"
            elevation={0}
            sx={{ background: "transparent" }}
          >
            <Container maxWidth="lg">
              <Toolbar
                disableGutters
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Logo />
                <Button
                  variant="outlined"
                  href={
                    window.location.pathname !== "/saanvi" ? "/saanvi" : "/"
                  }
                  sx={{
                    color: "white",
                  }}
                >
                  {window.location.pathname !== "/saanvi"
                    ? "Talk to Saanvi"
                    : "Read news"}
                </Button>
                <Button
                  variant="outlined"
                  href="/login"
                  sx={{
                    color: "white",
                  }}
                >
                  Login to experience
                </Button>
              </Toolbar>
            </Container>
          </AppBar>
          <Container sx={{ padding: 2 }}>
            <Outlet />
          </Container>
        </Suspense>
      ),
      children: [
        { element: <HomePage />, index: true },
        {
          path: "articles/:articleId",
          element: <HomePage />,
          index: true,
        },
        { path: "saanvi", element: <SaanviPage /> },
      ],
    },
    {
      element: (
        <Authenticated>
          <DashboardLayout>
            <Suspense fallback={<AppInitView />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </Authenticated>
      ),
      children: [
        ...(isReadyAndLoggedIn
          ? [
              { element: <HomePage />, index: true },
              {
                path: "articles/:articleId",
                element: <HomePage />,
                index: true,
              },
            ]
          : []),
        { path: "explore", element: <TrendingPage /> },
        { path: "discover", element: <DiscoverPage /> },
        // auth must
        { path: "add", element: <AddArticlePage /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "saanvi", element: <SaanviPage /> },
        // end auth must
        { path: "profile/:ownerid", element: <ProfilePage /> },
      ],
    },

    {
      element: (
        <Suspense fallback={<AppInitView />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        { path: "signup", element: <SignupPage /> },
        { path: "login", element: <LoginPage /> },
        { path: "reset-password", element: <ResetPassword /> },
        {
          path: "verify-otp",
          element: <VerifyOTP />,
        },
        {
          path: "change-password/:email",
          element: <ChangePassword />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "terms",
          element: <Terms />,
        },
      ],
    },

    {
      path: "404",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <Page404 />
        </Suspense>
      ),
    },

    {
      path: "postAdvertisement",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AddAdvertisement />
        </Suspense>
      ),
    },
    {
      path: ":orgIdentifier",
      element: (
        <ValidateOrg>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </ValidateOrg>
      ),
      children: [
        { path: "feed", element: <FeedOrg /> },
        { path: "", element: <HomeOrg /> },
      ],
    },
    // {
    //   path: "*",
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  if (!isInitialized) return <AppInitView />;

  return routes;
}
