import FeedRoundedIcon from "@mui/icons-material/FeedRounded";
// import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ExploreRoundedIcon from "@mui/icons-material/ExploreRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Feed",
    path: "/",
    icon: <FeedRoundedIcon />,
  },
  {
    title: "Explore",
    path: "/explore",
    icon: <ExploreRoundedIcon />,
  },
  {
    title: "Post",
    path: "/add",
    icon: <AddCircleOutlineRoundedIcon />,
  },
  {
    title: "Saanvi",
    path: "/saanvi",
    icon: <AutoAwesomeRoundedIcon />,
  },
  {
    title: "Profile",
    path: "/profile",
    icon: <PersonRoundedIcon />,
  },
];

export default navConfig;
