import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Markdown } from "src/components/markdown";
import Scrollbar from "src/components/scrollbar";
import GppMaybeRoundedIcon from "@mui/icons-material/GppMaybeRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import { HEADER, NAV } from "src/layouts/dashboard/config-layout";
import axios from "axios";
import { keyframes } from "@emotion/react";
import useAuth from "src/hooks/useAuth";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/config/firebase.config";

const thinkingAnimation = keyframes`
  0% {
    background-position: 200% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const AnimatedText = styled(Typography)`
  background: linear-gradient(
    to right,
    #ffffff 8%,
    #e572e3,
    #7b79ff,
    #ffffff 92%
  );
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${thinkingAnimation} 2s linear infinite;
`;

const useStreamedPostReceiver = ({
  addChat,
  setLoading,
  setInCommingMessage,
  scrollToBottom,
}) => {
  const url = "https://ai.riple.org";
  // const url = "http://localhost:5000";

  const fetchStream = async (chats) => {
    setLoading(true);
    setInCommingMessage("");
    let inMessage = "";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ messages: chats }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `${axios.defaults.headers.common.Authorization}`,
        },
      });

      if (!response.ok) {
        let errorData;
        try {
          // Attempt to parse JSON response
          errorData = await response.json();
        } catch {
          // If parsing fails, fallback to text
          errorData = await response.text();
        }

        if (errorData.errorCode === "SAANVI-LIMIT-EXCEEDED") {
          localStorage.setItem(
            "SAANVI-LIMIT-EXCEEDED",
            "SAANVI-LIMIT-EXCEEDED"
          );
          await new Promise((resolve) => setTimeout(resolve, 500));

          const _chatRes =
            "You need to login to continue talking to me. [Login](/login) or [register](/signup). You can then go to the **Saanvi** tab to talk to me again!";
          for (let _chatChunk of _chatRes.split(" ")) {
            await new Promise((resolve) => setTimeout(resolve, 75));
            setInCommingMessage((prev) => prev + " " + _chatChunk);
          }
          addChat({
            role: "assistant",
            content: _chatRes,
          });
          scrollToBottom();
          return;
        }

        // eslint-disable-next-line no-throw-literal
        throw {
          status: response.status,
          statusText: response.statusText,
          body: errorData,
        };
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          addChat({ role: "assistant", content: inMessage });
          setLoading(false);
          setInCommingMessage("");

          break;
        }

        const chunkText = decoder
          .decode(value, { stream: true })
          .replace(/^data: /, "")
          .replace(/\\n\\n$/, "");

        if (/\[DONE\]/.test(chunkText)) {
          continue;
        }

        const splits = chunkText.split("\n\n").filter(Boolean);

        for (let i = 0; i < splits.length; i++) {
          const parsedChunkText = JSON.parse(
            splits[i].replace(/^data: /, "").replace(/\\n\\n$/, "") || ""
          );
          const _m = parsedChunkText?.choices?.[0]?.delta?.content;

          inMessage += _m;
          setInCommingMessage((prev) => prev + _m);
        }
        scrollToBottom();
      }
    } catch (err) {
      if (err.status) {
        // Handle HTTP errors (server returned an error response)
        console.error(`HTTP Error ${err.status}: ${err.statusText}`, err.body);
      } else {
        // Handle network errors (e.g., no internet, CORS issues)
        console.error("Network or Fetch error:", err.message);
      }
      console.error("Streaming error:", err);
    } finally {
      setLoading(false);
    }
  };

  return { send: fetchStream };
};

export default function SaanviPage() {
  const { isAuthenticated } = useAuth();

  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");

  const [inCommingMessage, setInCommingMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const messagesEndRef = useRef();
  const scrollRef = useRef();

  const scrollToBottom = () => {
    scrollRef.current?.scrollTo(0, messagesEndRef.current?.scrollHeight);
  };

  const { send } = useStreamedPostReceiver({
    addChat: (chat) => setChat((prev) => [...prev, chat]),
    setLoading,
    setInCommingMessage,
    scrollToBottom,
  });

  const handleSendMessage = (e) => {
    e.preventDefault();
    logEvent(analytics, "button_click", { button_name: "Saanvi Chat Send" });

    const limitExceeded =
      !!localStorage.getItem("SAANVI-LIMIT-EXCEEDED") && !isAuthenticated;

    if (loading || !message.trim() || limitExceeded) return;
    const newChat = [...chat, { role: "user", content: message.trim() }];
    setChat(newChat);
    send(newChat);

    setMessage("");
    setTimeout(() => scrollToBottom(), 10);
  };

  return (
    <>
      <Helmet>
        <title> Riple - Saanvi Chat </title>
      </Helmet>

      <Container
        sx={{
          ...(isMobile ? { pl: 0, pr: 0, paddingTop: 2 } : {}),
          height: `calc(100dvh - ${
            isMobile ? NAV.MOBILE : HEADER.H_DESKTOP + 20
          }px)`,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <AppBar
          position="static"
          sx={{ background: "transparent" }}
          elevation={0}
        >
          <Toolbar>
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems={isMobile ? "start" : "center"}
              justifyContent={isMobile ? "center" : "start"}
              gap={2}
              height="70px"
              flexGrow={1}
            >
              <Box
                component="img"
                src="/assets/saanvi_logo.png"
                height="20px"
                width="200px"
                sx={{ objectFit: "contain" }}
              />
              <Chip
                size="small"
                label="EXPERIMENTAL"
                color="warning"
                variant="outlined"
                sx={{ fontSize: "8px" }}
              />
            </Stack>
            <Button
              onClick={() => setChat([])}
              startIcon={<RestartAltRoundedIcon />}
              color="secondary"
              variant="outlined"
              size="small"
            >
              New Chat
            </Button>
          </Toolbar>
        </AppBar>

        {Boolean(!chat.length) && (
          <Stack
            sx={{ flexGrow: "1", p: 2 }}
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <AnimatedText variant="h2" color="secondary">
              Hello, I'm Saanvi
            </AnimatedText>
            <Typography variant="h4" fontWeight="500" align="center">
              What would you like to know today?
            </Typography>
          </Stack>
        )}

        {Boolean(chat.length) && (
          <Scrollbar ref={scrollRef} sx={{ flexGrow: "1" }}>
            <Stack
              ref={messagesEndRef}
              gap={2}
              alignItems="flex-end"
              my={2}
              flexGrow={1}
              minHeight="92%"
            >
              {chat?.map((message, idx) => (
                <Card
                  key={idx}
                  sx={{
                    maxWidth: message.role === "user" ? "80%" : "100%",
                    width: message.role === "user" ? "auto" : "100%",
                    bgcolor: message.role !== "user" && "transparent",
                    px: 2,
                    py: 0,
                    ...(isMobile
                      ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                      : {}),
                  }}
                >
                  <Typography variant="p" key={idx}>
                    <Markdown>{message.content}</Markdown>
                  </Typography>
                </Card>
              ))}
              {loading && (
                <Card
                  sx={{
                    width: "100%",
                    bgcolor: "transparent",
                  }}
                >
                  <CardContent>
                    <Collapse
                      in={loading && !inCommingMessage}
                      orientation="vertical"
                    >
                      <AnimatedText variant="subtitle1">
                        Thinking about it...
                      </AnimatedText>
                    </Collapse>
                    <Markdown>{inCommingMessage}</Markdown>
                  </CardContent>
                </Card>
              )}
            </Stack>
          </Scrollbar>
        )}

        <Stack direction="column" width="100%" gap={1} flexShrink={1} p={1}>
          {/* <Card>
            <CardContent> */}
          <form onSubmit={handleSendMessage}>
            <TextField
              fullWidth
              autoComplete="off"
              name="answer"
              placeholder="Type here"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ pl: 1 }}>
                    {!loading && message && (
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                      >
                        <SendRoundedIcon color="inherit" />
                      </Button>
                    )}

                    {loading && (
                      <CircularProgress size={20} thickness={4} color="info" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </form>
          {/* </CardContent>
          </Card> */}

          <Alert
            icon={<GppMaybeRoundedIcon sx={{ fontSize: 20 }} />}
            severity="warning"
            sx={{ fontSize: "10px", opacity: 0.9 }}
          >
            SAANVI can make mistakes. Please double-check responses.
          </Alert>
        </Stack>
      </Container>
    </>
  );
}
